/* Provide sufficient contrast against white background */
body {
    background-color: #EFF9FF;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.header {
    border: 1px dotted #1861ac;
    background-color: #C9EBFF;
}

.headerHome {
    border: 1px dotted #1861ac;
    background-color: #C9EBFF;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
    color: #1861ac;
    padding: 15px;
}

.btn_register {
    background-color: #FF7F7F;
}

.btn {
    background-color: lightblue;
}

.btn_login {
    background-color: lightblue;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.collection {
    display: inline-block;
    width: 300px;
    text-align: center;
}

    .collection img {
        border-radius: 50%;
        width: 250px;
        height: 150px;
        margin-top: 10px;
    }

        .collection img:hover {
            border-radius: 20%;
            transform: scale(1.1);
            cursor: pointer;
        }

.tradeuser {
    display: inline-block;
    width: 30%;
    text-align: left;
    padding: 15px;
    border: 1px dotted #ccc;
    margin: 2%;
    background-color: #EFF9FF;
    cursor: pointer;
}

.collectionItem {
    display: inline-block;
    width: 200px;
    text-align: center;
    padding: 5px;
    border: 1px dotted #ccc;
    margin: 2%;
    background-color: #EFF9FF;
}

    .collectionItem img {
        width: 150px;
        opacity: 0.3;
        cursor: pointer;
    }

        .collectionItem img.owned {
            opacity: 1;
            cursor: default;
        }


        .collectionItem img.wantToTrade {
            opacity: 1;
        }

    .collectionItem .qty {
        width: 40px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
    }

    .collectionItem .qtybtn {
        width: 25px;
        height: 25px;
        background-color: lightblue;
        margin: 2%;
        padding: 0px;
    }

.collectionContainer, .tradeUsersContainer, .tradeRequestsContainer {
    text-align: center;
}

.collectionButtons {
    border: 1px dotted #1861ac;
    padding: 2%;
    background-color: #C9EBFF;
}

    .collectionButtons .btn {
        background-color: lightblue;
    }

.selectedCollectionDetails {
    border: 1px dotted #1861ac;
    padding: 2%;
    background-color: #C9EBFF;
    margin-top: 15px;
    margin-bottom: 15px;
}

    .selectedCollectionDetails .btn {
        background-color: lightblue;
    }

.collectionItems, .tradeUsers, .tradeRequests {
    border: 1px dotted #1861ac;
    background-color: #C9EBFF;
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.traderequest {
    display: inline-block;
    width: 300px;
    height: 400px;
    text-align: left;
    padding: 5px;
    border: 1px dotted #ccc;
    margin: 2%;
    vertical-align: top;
    position: relative;
    overflow-y:auto;
}


    .traderequest.active {
        background-color: #EFF9FF;
    }

    .traderequest.inactive {
        background-color: #C9EBFF;
    }

    .traderequest .postaladdress {
        text-align: center;
    }

    .traderequest .displayUser {
        display: inline-block;
        font-weight: bold;
    }

    .traderequest .icon {
        float: right;
        width: 40px
    }

    .traderequest .tradeImages {
        padding-top: 30px;
        padding-bottom: 30px;
    }

        .traderequest .tradeImages .left {
            width: 50%;
            display: inline-block;
            text-align: center;
        }


        .traderequest .tradeImages .right {
            width: 50%;
            display: inline-block;
            text-align: center;
        }

    .traderequest .buttonbar {
        position: absolute;
        bottom: 0px;
        text-align: center;
        width: 90%;
        padding-bottom: 5%;
        padding-top: 5%;
    }


    .traderequest .buttonbarrelative {
        text-align: center;
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
    }

    .traderequest .btn {
        background-color: lightblue;
    }

    .traderequest .disabled {
        cursor: default;
    }

    .traderequest .accept {
        background-color: limegreen;
    }

    .traderequest .status {
        border: 1px dotted #ccc;
        margin: 3px;
    }
